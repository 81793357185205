import type { Styles } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

/**
 * Fixing footer to the bottom of the page technique with flexbox borrowed from
 * @see https://greggod.medium.com/keep-that-damn-footer-at-the-bottom-959796fe3d08
 */
const styles: Styles = {
  global: (props) => ({
    body: {
      bg: mode('white', 'ivtBlue')(props),
      color: mode('ivtBlue', 'white')(props),
      '#redwood-app': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
      main: { mb: 'auto' },
    },
  }),
}

export default styles
