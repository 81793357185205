import type { ButtonProps, ToastProviderProps } from '@chakra-ui/react'

/**
 * @todo get rid of this file ("config" will be a totally different beast for #121)
 */
export const toastConfig: ToastProviderProps = {
  defaultOptions: {
    position: 'top',
    duration: 9000,
    isClosable: true,
    variant: 'subtle',
    containerStyle: {
      marginTop: '5rem',
    },
  },
}

/**
 * @todo what is so special about this button? Looks like an everyday ivt-solid variant
 */
export const exitButtonStyles: ButtonProps = {
  variant: 'ivt-solid',
  rounded: 'full',
  // TODO: remove?
  // textTransform: 'uppercase',
  // TODO: lock on DarkMode color (remove when this is on an own page again)
  _disabled: {
    cursor: 'not-allowed',
    color: 'grey',
    bgColor: 'lightGrey',
  },
}
