import type { ComponentStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const MenuStyles: ComponentStyleConfig = {
  variants: {
    'ivt-profile': (props) => ({
      groupTitle: {},
      item: {
        color: mode('ivtBlue', 'white')(props),
        py: '.75rem',
        _focus: {
          bg: mode('concord', 'glass.20')(props),
        },
        _active: {
          bg: mode('smokeyGrey', 'glass.30')(props),
        },
      },
      list: {
        boxShadow:
          '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
      },
    }),
  },
}

export default MenuStyles
