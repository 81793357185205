import type { ComponentStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const boxSize = {
  width: 72,
  height: 14,
}

const boxBorderReset = {
  borderColor: 'ivtBlue',
  boxShadow: 'none',
}

const InputStyles: ComponentStyleConfig = {
  variants: {
    // for use in conjunction with <FormControl variant="floating" />
    solid: (props) => ({
      field: {
        borderStyle: 'solid',
        borderWidth: mode('1px', '0px')(props),
        bgColor: 'white',
        color: 'ivtBlue',
        ...boxBorderReset,
        _focus: boxBorderReset,
        _invalid: boxBorderReset,
        _hover: boxBorderReset,
        _autofill: {
          // dirty hack to make chrome behave from https://stackoverflow.com/questions/70809036/
          boxShadow: '0 0 0px 100vw white inset',
          WebkitTextFillColor: '#272a40', // ← bgDark (TODO: find a way to refer to the color name)
        },
        ...boxSize,
      },
      addon: boxSize,
    }),
  },
}

export default InputStyles
