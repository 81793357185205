import { PublicClientApplication } from '@azure/msal-browser'

import { createAuth } from '@redwoodjs/auth-azure-active-directory-web'

import * as variables from 'src/utils/variables'

const azureActiveDirectoryClient = new PublicClientApplication({
  auth: {
    clientId: variables.getAzureActiveDirectoryClientId(),
    authority: variables.getAzureActiveDirectoryAuthority(),
    redirectUri: variables.getAzureActiveDirectoryRedirectUri(),
    postLogoutRedirectUri: variables.getAzureActiveDirectoryLogoutRedirectUri(),
  },
})

export const { AuthProvider, useAuth } = createAuth(azureActiveDirectoryClient)
