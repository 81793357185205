import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

enum STATUS {
  info,
  warning,
  success,
  error,
}

const StatusColors = {
  blue: STATUS.info,
  orange: STATUS.warning,
  green: STATUS.success,
  red: STATUS.error,
}

const baseStyle = definePartsStyle({
  container: {
    px: 2,
    py: '.375rem', // 6px
    borderRadius: '.375rem', // 6px (figma says 5px, but, well...)
    width: ['full', 'max-content'],
    minH: ['1.875rem', '2rem'], // ensure consistent height for empty content
  },
  title: {
    lineHeight: [4, 5],
    fontWeight: 'normal',
    fontSize: ['sm', 'md'], // 14px, 16px
    width: 'full',
  },
})

const AlertStyles = defineMultiStyleConfig({
  variants: {
    // customization inspired from https://stackoverflow.com/questions/69531448#70378452
    'ivt-snackbar': (props) => {
      const result = (bgColor: string, color = 'ivtBlue') => {
        return {
          container: {
            bg: 'none',
            backgroundColor: bgColor,
            color: color,
            ...baseStyle.container,
          },
          title: baseStyle.title,
        }
      }

      const { colorScheme: c } = props

      switch (StatusColors[c]) {
        case STATUS.error:
          return result('vibrantRed', 'white')
        case STATUS.warning:
          return result('lockYellow')
        case STATUS.success:
          return result(mode('actionGreenLight', 'actionGreen')(props))
        case STATUS.info:
          return result(mode('roseLight', 'rose')(props))
      }
    },
  },
})

export default AlertStyles
