// https://chakra-ui.com/docs/theming/customize-theme
// https://chakra-ui.com/guides/recipes/using-fonts

// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src
//   https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/typography.ts

import { extendBaseTheme } from '@chakra-ui/react'
import { CalendarDefaultTheme } from '@uselessdev/datepicker'

// Component style overrides
import Alert from './components/alert'
import Button from './components/button'
import CloseButton from './components/close-button'
import DatePicker from './components/datepicker'
import Form from './components/form'
import Input from './components/input'
import Menu from './components/menu'
// Main chakra config
import config from './config'
// Foundational style overrides
import colors from './foundations/colors'
import typography from './foundations/typography'
// Global style overrides
import styles from './styles'

const overrides = {
  config,
  styles,
  ...typography,
  colors,
  // Other foundational style overrides go here
  components: {
    Alert,
    Button,
    CloseButton,
    Input,
    Form,
    Menu,
    ...DatePicker,
  },
}

export default extendBaseTheme(CalendarDefaultTheme, overrides)
