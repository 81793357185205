/*
 * https://stackoverflow.com/questions/22252472/how-can-i-change-the-color-of-an-svg-element/53336754#53336754
 * https://stackoverflow.com/a/43960991/604861
 * https://codepen.io/sosuke/pen/Pjoqqp
 */
export const colorFilters = {
  blue: 'invert(16%) sepia(7%) saturate(6579%) hue-rotate(189deg) brightness(100%) contrast(83%)', // #29395e
  white:
    'invert(100%) sepia(0%) saturate(7451%) hue-rotate(148deg) brightness(109%) contrast(102%)', // #ffffff
}

export type Colors = typeof colors

interface InterviewToolColors {
  /** "Disabled" (Figma name) */
  lightGrey: string
  /** "Interview Background" (Figma name) */
  mirage: string
  /** "Icon Background Interview" (Figma name) */
  grey: string
  /** "Primary Color Light Mode" (Figma name) */
  smokeyGrey: string
  /** "primary color light mode tone" (Figma name) */
  concord: string
  /** transparency gradings to add brighness */
  glass: { [k: number]: string }
  /** transparency gradings to add darkness  */
  darken: { [k: number]: string }
  [k: string]: string | { [k: number]: string }
}

const colors: InterviewToolColors = {
  mutedVideoGrey: '#3d4043',
  rose: '#edcfc0',
  ivtBlue: '#29395e',
  lightBlue: '#d4d4d9', // = smokeyGrey on white background
  midBlue: '#798ead',
  pitchBlue: '#222a3e',
  glass: {
    10: 'rgba(255, 255, 255, .1)',
    20: 'rgba(255, 255, 255, .2)',
    30: 'rgba(255, 255, 255, .3)',
  },
  darken: {
    38: 'rgba(0, 0, 0, .38)',
    60: 'rgba(0, 0, 0, .6)',
    87: 'rgba(0, 0, 0, .87)',
  },
  silverChalice: '#acacac',
  lockYellow: '#f8ce3a',
  vibrantRed: '#e53737',
  grey: 'rgba(88, 88, 88, .6)',
  actionGreen: '#1beb62',
  paleGreen: '#50de80',
  mirage: '#202124',
  bgLightPale: '#767a88', // only used once (avatar background-color)
  lightWhite: '#f2f2f2',
  mistBlue: '#686b7a', // only used in two places project-wide
  lighterGrey: '#dddddd',
  lightGrey: '#c2c2c2',
  smokeyGrey: 'rgba(39, 42, 64, .2)',
  roseLight: '#ffe9de',
  actionGreenLight: '#bcfcd2',
  concord: 'rgba(39, 42, 64, .1)',
}

export default colors
