import { toVarDefinition } from '@chakra-ui/react'
import type { SystemStyleObject } from '@chakra-ui/theme-tools'
import { cssVar } from '@chakra-ui/theme-tools'

import config from '../config'

const buttonSizeVar = toVarDefinition('close-button-size')
const size = (size) =>
  cssVar(`sizes-${size}`, { prefix: config.cssVarPrefix }).reference

const sizes: Record<string, SystemStyleObject> = {
  'r-lg': { [buttonSizeVar]: size(10), fontSize: size(4) },
  'r-md': { [buttonSizeVar]: size(8), fontSize: size(3) },
  'r-sm': { [buttonSizeVar]: size(6), fontSize: size(2.5) },
}

export default { sizes }
