import type { CSSObject } from '@chakra-ui/react'
import { Icon, useColorModeValue } from '@chakra-ui/react'

// see also for alternate ways of dynamic loading
// https://medium.com/@barrbozzodeveloper/the-right-way-to-use-svg-icons-with-react-e1bcab58d65a
import LogoSvg from './AppLogo.svg'
import LogoSvgSmall from './AppLogoSmall.svg'

// TODO Check if  applies (Safari)

function AppLogo({ isSmall = false, ...props }) {
  /**
    * It would be preferable to simply link to the SVG, thus leveraging browser cache
    * and saving ~6 kB on each page request such as this:
    *
      <Image sx={{ colorScheme: useColorModeValue('light', 'dark') }} src="/logo.svg" />
    *
    * This works perfectly in Firefox and Chromium as per the spec – however, there is a
    * bug in Safari and a limitation in Samsung's Stock Android Browser stopping the show here.
    *
    * @see https://www.ctrl.blog/entry/svg-embed-dark-mode.html
    * @see https://bugs.webkit.org/show_bug.cgi?id=199134 (Safari Bug)
    */

  const lightStyle: CSSObject = {
    '&#ivt-logo path': { fill: '#2e365b' },
    '.ivt-logo-box': { strokeWidth: '2px' },
    '.ivt-logo-text-bold': { visibility: 'visible' },
  }

  const darkStyle: CSSObject = {
    '&#ivt-logo path': { fill: '#fff' },
    '.ivt-logo-box': { strokeWidth: '0px' },
    '.ivt-logo-text-bold': { visibility: 'hidden' },
  }

  return (
    <Icon
      sx={useColorModeValue(lightStyle, darkStyle)}
      height={!isSmall ? [8, 12] /* 32px, 48px */ : 8}
      width={!isSmall ? ['7rem', '10.5rem'] /* 112px, 168px */ : '3rem'}
      {...props}
      // TODO: Dynamic single SVG as JSX instead of two separate SVG files ?
      as={!isSmall ? LogoSvg : LogoSvgSmall}
    />
  )
}

export default AppLogo
