let variables
export function getLiveKitRecDownloadUrl() {
  const variables = getVariables()
  const result =
    variables && variables.LIVEKIT_REC_DOWNLOAD_URL
      ? variables.LIVEKIT_REC_DOWNLOAD_URL
      : process.env.LIVEKIT_REC_DOWNLOAD_URL
  return result
}
export function getLivekitUrl() {
  const variables = getVariables()
  const result =
    variables && variables.LIVEKIT_URL
      ? variables.LIVEKIT_URL
      : process.env.LIVEKIT_URL
  return result
}

export function getAzureActiveDirectoryClientId() {
  const variables = getVariables()
  const result =
    variables && variables.AZURE_ACTIVE_DIRECTORY_CLIENT_ID
      ? variables.AZURE_ACTIVE_DIRECTORY_CLIENT_ID
      : process.env.AZURE_ACTIVE_DIRECTORY_CLIENT_ID
  return result
}

export function getAzureActiveDirectoryAuthority() {
  const variables = getVariables()
  const result =
    variables && variables.AZURE_ACTIVE_DIRECTORY_AUTHORITY
      ? variables.AZURE_ACTIVE_DIRECTORY_AUTHORITY
      : process.env.AZURE_ACTIVE_DIRECTORY_AUTHORITY
  return result
}

export function getAzureActiveDirectoryRedirectUri() {
  const variables = getVariables()
  const result =
    variables && variables.AZURE_ACTIVE_DIRECTORY_REDIRECT_URI
      ? variables.AZURE_ACTIVE_DIRECTORY_REDIRECT_URI
      : process.env.AZURE_ACTIVE_DIRECTORY_REDIRECT_URI
  return result
}

export function getAzureActiveDirectoryLogoutRedirectUri() {
  const variables = getVariables()
  const result =
    variables && variables.AZURE_ACTIVE_DIRECTORY_LOGOUT_REDIRECT_URI
      ? variables.AZURE_ACTIVE_DIRECTORY_LOGOUT_REDIRECT_URI
      : process.env.AZURE_ACTIVE_DIRECTORY_LOGOUT_REDIRECT_URI
  return result
}

const getVariables = () => {
  try {
    if (!variables) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', '/variables.json', false)
      xhr.send()
      variables = JSON.parse(xhr.responseText)
      //console.log('variables object: ')
      //console.log(variables)
    }
    return variables
  } catch (error) {
    console.log(error)
  }
}
